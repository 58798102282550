import React from "react";

const HalamanContoh = React.lazy(() => import("./halamanUtama/HalamanContoh"));
const TesQr = React.lazy(() => import("./edemo/TesQr"));
const WarrantyCheck = React.lazy(() => import("./eWarranty/WarrantyCheck"));
const PindahLokasi = React.lazy(() => import("./master/PindahLokasi"));
const Training = React.lazy(() => import("./eTraining/Training1"));
const Training2 = React.lazy(() => import("./eTraining/Training2"));
//const UserBaru = React.lazy(() => import("./master/UserBaru"));
const WarrantyList1 = React.lazy(() => import("./eWarranty/WarrantyList1"));
//const Ewarrantylama = React.lazy(() => import("./eWarranty/Ewarrantylama"));
const UploadBonus = React.lazy(() => import("./edemo/UploadBonus"));

const WarrantyList = React.lazy(() => import("./eWarranty/WarrantyList"));

const EdemoKunjungan = React.lazy(() => import("./edemo/EdemoKunjungan"));
const WarrantyReport1 = React.lazy(() => import("./eWarranty/EwarranyLaporan1"));
const PindahLokasiAcc = React.lazy(() => import("./master/PindahLokasiAcc"));

const Download = React.lazy(() => import("./master/Download"));

const DataSales = React.lazy(() => import("./master/DataSales"));
const Lokasi = React.lazy(() => import("./master/Lokasi"));

const PointWarranty = React.lazy(() => import("./master/PointWarranty"));
const PointEdemo = React.lazy(() => import("./edemo/PointEdemo"));
const PointEdemoPromo = React.lazy(() => import("./edemo/PointEdemoPromo"));

const DataDc = React.lazy(() => import("./master/DataDc"));


//const TestInvoice2 = React.lazy(() => import("./invoice/TestInvoice2"));


//------------------

const Halaman1 = React.lazy(() => import("./halamanUtama/halaman1"));
const EWarranty = React.lazy(() => import("./eWarranty/Ewarranty"));

const Kunjungan = React.lazy(() => import("./edemo/Kunjungan"));

const MyProfile = React.lazy(() => import("./master/MyProfile"));
//=======================================================================
//=======================================================================
const DataRegion = React.lazy(() => import("./master/DataRegionMaster"));
const DataLokasi = React.lazy(() => import("./master/DataLokasi"));

const DataAkses = React.lazy(() => import("./master/DataAkses"));
const DataCostCenter = React.lazy(() => import("./master/DataCostCenter"));
const DataSection = React.lazy(() => import("./master/DataSection"));
const PicArea = React.lazy(() => import("./master/PicArea"));
const DataArea = React.lazy(() => import("./master/LokasiCashteam"));
const DataJabatan = React.lazy(() => import("./master/DataJabatan"));
const DataDivisi = React.lazy(() => import("./master/DataDivisi"));
const ListDataPegawai = React.lazy(() => import("./masterdata/ListMasterData"));
const DaftarRh = React.lazy(() => import("./edemo/DaftarRh"));
const DaftarSm = React.lazy(() => import("./edemo/DaftarSm"));
const DaftarAsm = React.lazy(() => import("./edemo/DaftarAsm"));
const DaftarAnggota = React.lazy(() => import("./edemo/DaftarAnggota"));

//master-reminder-group
const routes2 = [  
  { path: "/data-rh", name: "DaftarRh", component: DaftarRh }, 
  { path: "/data-anggota", name: "DaftarRh", component: DaftarAnggota }, 
  { path: "/data-sm", name: "DaftarSm", component: DaftarSm }, 
  { path: "/data-asm", name: "DaftarAsm", component: DaftarAsm }, 
  { path: "/data-akses", name: "DataAkses", component: DataAkses }, 
  { path: "/data-region", name: "DataRegion", component: DataRegion },
  { path: "/data-lokasi", name: "DataLokasi", component: DataLokasi },
  { path: "/data-costcenter", name: "DataCostCenter", component: DataCostCenter },
  { path: "/data-section", name: "DataSection", component: DataSection },  
  { path: "/pic-lokasi-cashteam", name: "PicArea", component: PicArea }, 
  { path: "/data-area", name: "DataArea", component: DataArea },
  { path: "/data-jabatan", name: "DataJabatan", component: DataJabatan },
  { path: "/data-divisi", name: "DataDivisi", component: DataDivisi },
  { path: "/list-data-pegawai", name: "ListDataPegawai", component: ListDataPegawai },
  // ####################################################################
  { path: "/tes-qr", name: "TesQr", component: TesQr },
  { path: "/halaman-utama", name: "HalamanContoh", component: HalamanContoh },
  { path: "/upload-bonus", name: "UploadBonus", component: UploadBonus },  
  { path: "/point-edemo", name: "PointEdemo", component: PointEdemo },  
  { path: "/point-edemo-promo", name: "PointEdemoPromo", component: PointEdemoPromo },  
  
  //{ path: "/lokasi-cashteam", name: "LokasiCashteam", component: LokasiCashteam },  
  { path: "/pindah-lokasi-acc", name: "PindahLokasiAcc", component: PindahLokasiAcc },  
  { path: "/pindah-lokasi", name: "PindahLokasi", component: PindahLokasi },  
  
  
  { path: "/download-apk", name: "Download", component: Download },
  { path: "/edemo-list", name: "EdemoKunjungan", component: EdemoKunjungan },
  
  
  //{ path: "/contekan", name: "Contekan", component: Contekan },
  
  { path: "/update-training", name: "Training2", component: Training2 },
  { path: "/input-training", name: "Training", component: Training },
  { path: "/my-profile", name: "MyProfile", component: MyProfile },
  { path: "/waranty-check", name: "WarrantyCheck", component: WarrantyCheck },
  { path: "/daftar-sales", name: "DataSales", component: DataSales },
  { path: "/daftar-lokasi", name: "Lokasi", component: Lokasi },
  // { path: "/cari-nip", name: "CariNip", component: CariNip },
  // { path: "/input-penjualan", name: "Penjualan1", component: Penjualan1 },
  // { path: "/input-penjualan2", name: "Penjualan2", component: Penjualan2 },
  { path: "/halaman-utama", name: "Halaman1", component: Halaman1 },
  
  { path: "/e-warranty", name: "EWarranty", component: EWarranty },
  
  
  { path: "/data-kunjungan", name: "Kunjungan", component: Kunjungan },
  

  //{ path: "/data-satuan", name: "DataSatuan", component: DataSatuan },
  
  // { path: "/data-jabatan", name: "DataJabatan", component: DataJabatan },
  
  //{ path: "/data-region", name: "DataRegion", component: DataRegion },
  { path: "/point-warranty", name: "PointWarranty", component: PointWarranty },
  { path: "/data-dc", name: "DataDc", component: DataDc },
  
  
  { path: "/waranty-list1", name: "WarrantyList1", component: WarrantyList1 },
  { path: "/waranty-list", name: "WarrantyList", component: WarrantyList},
  { path: "/waranty-report1", name: "WarrantyReport1", component: WarrantyReport1 },
  
];

export default routes2;
