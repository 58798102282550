import axios from "axios";
//import { confirmAlert } from "react-confirm-alert"; // Import
import { Button, Modal, Space,notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {Dialog,DialogActions,DialogContent,
  DialogContentText,DialogTitle} from '@mui/material';

//import "../react-confirm-alert.css"; // Import css

//const API_URL = "http://localhost:8080/api/auth/";
const { confirm } = Modal;
const url = process.env.REACT_APP_LOKASI_URL;

const axiosConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("bigStore2.jwt"),
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

class Koneksiku {
  

  xl_tulis=(ws,baris,kolom,nilai,tipe)=>{
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").value = nilai;
    /*
    if(tipe.toUpperCase()==='C'){
      ws.getCell('"'+sel+baris+"'").value = "'"+nilai+"'";
    }else{
      ws.getCell('"'+sel+baris+"'").value = nilai;
    } 
    */   
  }

  xl_tulis_red=(ws,baris,kolom,nilai,tipe)=>{
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").value = nilai;
    ws.getCell('"'+sel+baris+"'").font = {color: {argb: "EB2B02"}};
    
  }

  


  xl_tulis_tebal=(ws,baris,kolom,nilai,tipe)=>{
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").value = nilai;
    /*
    if(tipe.toUpperCase()==='C'){
      ws.getCell('"'+sel+baris+"'").value = "'"+nilai+"'";
    }else{
      ws.getCell('"'+sel+baris+"'").value = nilai;
    } 
    */   
    ws.getCell('"'+sel+baris+"'").font = {
      size: 13,
      //italic: true,
      bold: true
    };
  }

  xl_center(ws,baris,kolom){
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").alignment = { vertical: 'top', horizontal: 'center' };
  }

  xl_center_area(ws,awal_br,awal_kol,akhir_br,akhir_kol){
    for (let index = awal_kol; index <= akhir_kol; index++) {
    
      for (let index2 = awal_br; index2 <= akhir_br; index2++) {      
        let sel = this.abjat(index)
        ws.getCell('"'+sel+index2+"'").alignment = { vertical: 'top', horizontal: 'center' };  
      }
      
    }
  }

  xl_wrap(ws,baris,kolom){
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").alignment = { 
      wrapText: true,
      vertical: 'top', horizontal: 'left'
     };  
  }

  xl_center_area_wrap(ws,awal_br,awal_kol,akhir_br,akhir_kol){
    for (let index = awal_kol; index <= akhir_kol; index++) {
    
      for (let index2 = awal_br; index2 <= akhir_br; index2++) {      
        let sel = this.abjat(index)
        ws.getCell('"'+sel+index2+"'").alignment = { vertical: 'middle', horizontal: 'center',wrapText: true };  
      }
      
    }
  }


  xl_fullborder(excel,awal_br,awal_kol,akhir_br,akhir_kol){
    
      for (let i = awal_br; i <= akhir_br; i++) {
          for (let i2 = awal_kol; i2 <= akhir_kol; i2++) {
              let sel=this.abjat(i2)+i
              //console.log('telo '+new Date(),'apakah ${sel}')
              
              excel.getCell('"'+sel+"'").border = {
                  top: {style:'thin', 
                    //color: {argb:'FF00FF00'}
                  },
                  left: {style:'thin'},
                  bottom: {style:'thin'},
                  right: {style:'thin'}
                };
              
          }        
      }
        
  }

  xl_border_bottom(excel,awal_br,awal_kol,akhir_br,akhir_kol){
    
    for (let i = awal_br; i <= akhir_br; i++) {
        for (let i2 = awal_kol; i2 <= akhir_kol; i2++) {
            let sel=this.abjat(i2)+i
            //console.log('telo '+new Date(),'apakah ${sel}')
            
            excel.getCell('"'+sel+"'").border = {
              left: {style:'thin'},
                  bottom: {style:'thin'},
                  right: {style:'thin'}
              };
            
        }        
    }
      
}

  xl_border_leftright(excel,awal_br,awal_kol,akhir_br,akhir_kol){
    
    for (let i = awal_br; i <= akhir_br; i++) {
        for (let i2 = awal_kol; i2 <= akhir_kol; i2++) {
            let sel=this.abjat(i2)+i
            //console.log('telo '+new Date(),'apakah ${sel}')
            
            //const telo=excel.getCell('"'+sel+"'").border

            excel.getCell('"'+sel+"'").border = {
              diagonal: {up: false, down: false},
              left: {style:'thin'},
              right: {style:'thin'}
            };
            
        }        
    }
      
}

  xl_OuterBorder(worksheet,awal_br,awal_kol,akhir_br,akhir_kol){
  //xl_OuterBorder = (worksheet, start = {row: 1, col: 1}, end = {row: 1, col: 1}, borderWidth = 'medium') => {

    const borderStyle = {
        style: 'thin'
    };
    for (let i = awal_br; i <= akhir_br; i++) {
        const leftBorderCell = worksheet.getCell(i, awal_kol);
        const rightBorderCell = worksheet.getCell(i, akhir_kol);
        leftBorderCell.border = {
            ...leftBorderCell.border,
            left: borderStyle
        };
        rightBorderCell.border = {
            ...rightBorderCell.border,
            right: borderStyle
        };
    }

    for (let i = awal_kol; i <= akhir_kol; i++) {
        const topBorderCell = worksheet.getCell(awal_br, i);
        const bottomBorderCell = worksheet.getCell(akhir_br, i);
        topBorderCell.border = {
            ...topBorderCell.border,
            top: borderStyle
        };
        bottomBorderCell.border = {
            ...bottomBorderCell.border,
            bottom: borderStyle
        };
    }
};

  xl_format_cell(ws,baris,kolom,digit){
    let sel=this.abjat(kolom)+baris
    if(digit===0){
      ws.getCell("'"+sel+"'").numFmt = '#,##0.00;[Red]-#,##0';
    }else if(digit===1){
      ws.getCell("'"+sel+"'").numFmt = '#,##0.00;[Red]-#,##0.0';
    }else if(digit===2){
      ws.getCell("'"+sel+"'").numFmt = '#,##0.00;[Red]-#,##0.00';
    }
  }

  xl_format_col(ws,kolom,digit){
    if(digit===0){
      ws.getColumn(kolom).numFmt = '#,##0.00;[Red]-#,##0';
    }else if(digit===1){
      ws.getColumn(kolom).numFmt = '#,##0.00;[Red]-#,##0.0';
    }else if(digit===2){
      ws.getColumn(kolom).numFmt = '#,##0.00;[Red]-#,##0.00';
    }
  }
  

  xl_autowidht2(ws,awal_br,awal_kol,akhir_br,akhir_kol){
    //ws.getColumn(kolom).width=nilai
    for (let index = awal_kol; index <= akhir_kol; index++){
      
      let maxLength=0;
      for (let index2 = awal_br; index2 <= akhir_br; index2++){
        let dt=ws.getRow(index2).getCell(index).toString();
        var columnLength = dt ? dt.length : 10;
        if (columnLength > maxLength ) {
          maxLength = columnLength;
        }
      }
      //console.log('kolom '+index,maxLength < 10 ? 10 : maxLength)

    }

    

  }


  xl_width(ws,kolom,nilai){
    ws.getColumn(kolom).width=nilai
  }

  xl_autowidht(ws){
    ws.columns.forEach(function (column, i) {
      var maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length+5 : 10;
          if (columnLength > maxLength ) {
              maxLength = columnLength;
          }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });
  }

  xl_autowidht5(ws){
    for (let index = 1; index <= 3; index++){
      let dobCol = ws.getColumn(index);
      let maxLength=0;
      dobCol.eachCell({ includeEmpty: true }, function (cell) {
        var columnLength = cell.value ? cell.value.toString().length+3 : 10;
            if (columnLength > maxLength ) {
                maxLength = columnLength;
            }
      });
      //console.log('kolom '+index,maxLength < 10 ? 10 : maxLength)
      dobCol.width= maxLength < 10 ? 10 : maxLength;
    }

  }


  xl_autowidht3(ws){
    
    for (let index = 1; index <= 3; index++){
      let dobCol = ws.getColumn(index);
      let maxLength=0;
      dobCol.eachCell({ includeEmpty: true }, function (cell) {
      //dobCol.eachCell(function(cell, rowNumber) {
        var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength ) {
                maxLength = columnLength;
            }
      });
      //console.log('kolom '+index,maxLength < 10 ? 10 : maxLength)
      dobCol.width= maxLength < 10 ? 10 : maxLength;
    }

   /*
    

    ws.columns.forEach(function (column, i) {
      var maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength ) {
              maxLength = columnLength;
          }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
      
  });
  */

  }

  xl_right(ws,baris,kolom){
    let sel = this.abjat(kolom)
    ws.getCell('"'+sel+baris+"'").alignment = { vertical: 'top', horizontal: 'right' };

  }

  xl_merge(ws,awal_br,awal_kol,akhir_br,akhir_kol){
    let awal = this.abjat(awal_kol)+awal_br
    let akhir = this.abjat(akhir_kol)+akhir_br
    let posisi="'"+awal+"':'"+akhir+"'"
    ws.mergeCells(posisi);
  }


  abjat=(num)=>{
    var s = '', t;
  
    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = (num - t)/26 | 0;
    }
    return s || undefined;
  }

  tanya(pesan) {
    return new Promise((resolve, reject) => {
        confirm({
            title: pesan,
            icon: <ExclamationCircleOutlined />,
            //content: pesan,
            className: 'idpesan',
            onOk() {
                resolve(1);
            },
        
            onCancel() {
                resolve(0)
            },
          });

    });
  }

  pesan(pesan) {    
    /*
      notification.open({
        message: pesan,
        //description: 'New description.',
      });
      */
      notification['info']({
        message: <hr style={{height: '5px',backgroundColor: 'green',color: 'green'}}/>,
        description: pesan
      });
  }

  error(pesan) {    
    /*
      notification.open({
        message: pesan,
        //description: 'New description.',
      });
      */
      notification['info']({
        message: <hr style={{height: '5px',backgroundColor: 'red',color: 'red'}}/>,
        description: pesan
      });
  }

  pesan2(pesan) {
    Modal.confirm({
        cancelButtonProps: {
            disabled: true,
            style: {display: 'none'}
          },
        title: pesan,
        icon: <ExclamationCircleOutlined />,
        okText: 'Oke',
      });
  }


  
  dynamicSort(property) {
    return function (obj1, obj2) {
      return obj1[property] > obj2[property]
        ? 1
        : obj1[property] < obj2[property]
        ? -1
        : 0;
    };
  }

  dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
      var i = 0,
        result = 0,
        numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    };
  }

  getAxio(alamat) {
    return axios.get(url + "/" + alamat, axiosConfig).then((response) => {
      //return response;
      let te = JSON.stringify(response);
      let te2 = JSON.parse(te);
      te = null;
      //console.log(te2.data,"### haddodoooo ##");
      return te2;
    });
  }

  getAxio2(alamat) {
    const dataKota5 = {
      34: "İstanbul",
      63: "Şanlıurfa",
      13: "Jakarta",
      62: "Surabaya",
    };
    return dataKota5;
  }

  dapatAkses(dicari) {
    let k=localStorage.getItem("hakakses");
    if(k!==null && k!==undefined){
      let hakakses = JSON.parse(k);
      //console.log('gagak '+new Date(),hakakses)
      //console.log('gagak '+new Date(),dicari)
      let boleh=false;
      for (let index5 = 0; index5 < hakakses.length; index5++) {
        const el5 = hakakses[index5];
        if(el5.ket1.trim()===dicari){
          boleh=true;
          break;
        }
      }
      return boleh;
    }else{
      return false;
    }    
  }

  postAxio(alamat, param1) {
    return axios
      .get(url + "/" + alamat, param1, axiosConfig)
      .then((response) => {
        let te = JSON.stringify(response);
        let te2 = JSON.parse(te);

        return te2.data.duta;
      });
  }

  getUrl = () => {
    return process.env.REACT_APP_LOKASI_URL;
  };

  getAxiosConfig = () => {
    //console.log('kokok6b','duuh');
    //const telo=localStorage.getItem("bigStore2.jwt");
    //console.log('kokok6',telo);
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bigStore2.jwt"),
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  //localStorage.getItem('bigStore2.jwt')
  getUser = () => {
    const kas2a = localStorage.getItem("bigStore2.user");
    //let kas2=JSON.parse(kas2a);
    //alert(kas2.tenant);

    //let k=localStorage.getItem('bigStore2.user');
    //let te=JSON.stringify(k);
    //let te2=JSON.parse(k);
    if(kas2a!==null){
        return {hasil: true, nilai: JSON.parse(kas2a)};
    }else{
        return {hasil: false}
    }
        
  };

  getInit =()=>{
    const k = localStorage.getItem("init1");
    if(k!==null){
        return {hasil: true,
            nilai: JSON.parse(k)
        }
    }else{
        return {hasil: false}
    }
  }

  garisHori = ( color) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 4,
      }}
    />
  );

  garisHori2 = ( color,size) => (
    <hr
      style={{
        color: color,        
        backgroundColor: color,
        width: size,
        height: 4,
      }}
    />
  );

  getAkses =()=>{
    const k = localStorage.getItem("hakakses");
    if(k!==null){
        return {hasil: true,
            nilai: JSON.parse(k)
        }
    }else{
        return {hasil: false}
    }
  }

  getImageKu =()=>{
    const k = localStorage.getItem("gambarku");
    if(k!==null){
        return {hasil: true,
            gambar: k==='' ? '': process.env.REACT_APP_LOKASI_IMAGE+'/'+k
        }
    }else{
        return {hasil: false}
    }
  }

  getLastLogin = () => {
    const k = localStorage.getItem("masukkelas");
    if(k!==null){
        return {hasil: true,
            tgl: k
        }
    }else{
        return {hasil: false}
    }
        
  };

  logout() {
    localStorage.removeItem("masukkelas");
          localStorage.removeItem("bigStore2.user");
          localStorage.removeItem("bigStore2.jwt");
          localStorage.removeItem("hakakses");
          localStorage.removeItem("gambarku");
          localStorage.removeItem("init1");
          localStorage.removeItem("induk1");          
  }
  /*
  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
  */
}

export default new Koneksiku();
